import { createContext, DragEventHandler, useContext } from 'react'

import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'
import { Chart, Index, Items, Link } from 'regraph'

import { GraphLayout } from 'Components/Blocks/Graph/constants'
import {
  AnalyzerFunction,
  ICommunityNode,
  IItemData,
  ISkillNode,
  ITagNode,
} from 'Components/Blocks/Graph/utils'

import { QuickActionKind } from 'Constants/graph'
import { UserRelationshipStrength } from 'Constants/ids'

import { IOptions } from 'Pages/Community/Dashboard/Context'

export interface IGraphState {
  analyzerFunction: AnalyzerFunction | null
  combine?: Chart.CombineOptions
  items: Items<IItemData>
  layout?: Chart.LayoutOptions
  positions?: Chart.Positions
  selection?: Chart.Props['selection']
  view?: Chart.ViewOptions
  appendUsers?: IGraphPersonNode[]
  openCombos?: Record<string, boolean>
  firstLoad?: boolean
  forceLayoutReset?: boolean
  clusteringEnabled: boolean
  setClusteringEnabled: React.Dispatch<React.SetStateAction<boolean>>
  showRelationshipStrength: Record<string, boolean>
  setShowRelationshipStrength: React.Dispatch<
    React.SetStateAction<Record<string, boolean>>
  >
}

export interface ISubGraph {
  savedNodes: Items<IItemData>
  subGraph: Items<IItemData>
  positions?: Chart.Positions
}

export type AppendItemsHandler = ({
  users,
  skills,
  needSkills,
  tags,
  organizations,
  knowledge,
  areasOfExperience,
  communities,
}: {
  users?: IGraphPersonNode[]
  skills?: ISkillNode[]
  needSkills?: ISkillNode[]
  tags?: ITagNode[]
  organizations?: MainSchema.Organization[]
  knowledge?: MainSchema.KnowledgeGraphNode[]
  areasOfExperience?: MainSchema.AreaOfExperience[]
  communities?: ICommunityNode[]
}) => void

export type TemporaryConnectUserHandler = ({
  fromUserId,
  toUserId,
}: {
  fromUserId: string
  toUserId: string
}) => void

export interface LoadGraphState {
  clusteringEnabled: boolean
  showRelationshipStrength: Record<string, boolean>
  analyzerFunction: AnalyzerFunction | null
  nodes: Items<IItemData<any, any>>
  edges: Index<Link<IItemData>>
  layout?: Chart.LayoutOptions
  view?: Chart.ViewOptions
  positions?: Chart.Positions
  selection?: Chart.Props['selection']
  combine?: Chart.CombineOptions
  openCombos?: Record<string, boolean>
}

export interface IGraphMapper {
  handleAppendItems: AppendItemsHandler
  handleTemporaryConnectUser: TemporaryConnectUserHandler
  handleChangeAnalyzer: (analyzer: AnalyzerFunction | null) => void
  handleGraphReset: () => void
  handleLoadGraphState: (loadGraphState: LoadGraphState) => void
  handleExitSubGraph: () => void
  handleExternalDragDrop: DragEventHandler
  handleSubgraphSelectedItems: () => void
  handleRemoveSelected: () => void
  handleSetRelationshipStrength: ({
    strength,
    enabled,
  }: {
    strength: UserRelationshipStrength
    enabled: boolean
  }) => void
}

export interface IGraphHandlers {
  isDragging: boolean
  handleClick?: Chart.Props['onClick']
  handleDoubleClick?: Chart.Props['onDoubleClick']
  handleChange?: Chart.Props['onChange']
  handleHover?: Chart.Props['onHover']
  handleItemInteraction?: Chart.Props['onItemInteraction']
  handleDragStart?: Chart.Props['onDragStart']
  handleCombineNodes?: Chart.Props['onCombineNodes']
  handleCombineLinks?: Chart.Props['onCombineLinks']
  handleDragEnd?: Chart.Props['onDragEnd']
  handleDragOver?: Chart.Props['onDragOver']
  handleShowContextMenu?: Chart.Props['onContextMenu']
  handleLayoutGraph: () => void
  handleSetLayout: (layout: GraphLayout) => void
}

export interface IGraphQuery {
  handleSearch: ({
    limit,
    users,
    skills,
    needSkills,
    tags,
    organizations,
    communities,
  }: {
    limit?: number
    users?: string[]
    skills?: string[]
    needSkills?: string[]
    tags?: string[]
    organizations?: string[]
    communities?: string[]
  }) => Promise<void>
  handleLoadAllUsers: (communityIds: string[]) => Promise<void>
  handleLoadAllTags: () => void
  handleMyNetwork: (degrees: number) => void
  handleCommunitySearch: (item: any) => Promise<void>
}

export interface IShowGraphMenu {
  x: number
  y: number
}

export interface IGraphControls {
  reset?: boolean
  options?: boolean
  myNetwork?: boolean
  graphSnapshots?: boolean
  search?: boolean
  browse?: boolean
}

export interface IAvailableQuickAction {
  isEnabled: boolean
  handleClick: () => void
}

export interface IGraphContext {
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  isHandMode: boolean
  useQuickActions: boolean
  graphState: IGraphState
  setGraphState: React.Dispatch<React.SetStateAction<IGraphState>>
  showGraphMenu: IShowGraphMenu | null
  showContextMenuIds: string[]
  setShowContextMenuIds: React.Dispatch<React.SetStateAction<string[]>>
  subGraphs: ISubGraph[]
  graphControls?: IGraphControls
  graphMapper: IGraphMapper
  graphHandler: IGraphHandlers
  graphQuery: IGraphQuery
  quickActions: QuickActionKind[]
  availableQuickActions: Record<string, Record<string, IAvailableQuickAction>>
  currentGraphSnapshotId?: string
  setCurrentGraphSnapshotId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >
  loadGraphState: LoadGraphState
  setSavedLoadGraphState: React.Dispatch<
    React.SetStateAction<LoadGraphState | undefined>
  >
  options: IOptions | undefined
}

export const GraphContext = createContext<IGraphContext>({
  isLoading: false,
  setIsLoading: () => {},
  isHandMode: false,
  useQuickActions: false,
  graphState: {
    analyzerFunction: null,
    items: {},
    clusteringEnabled: false,
    setClusteringEnabled: () => {},
    showRelationshipStrength: {},
    setShowRelationshipStrength: () => {},
    view: undefined,
  },
  setGraphState: () => {},
  showGraphMenu: null,
  showContextMenuIds: [],
  setShowContextMenuIds: () => {},
  subGraphs: [],
  graphMapper: {
    handleAppendItems: () => {},
    handleTemporaryConnectUser: () => {},
    handleChangeAnalyzer: () => {},
    handleGraphReset: () => {},
    handleLoadGraphState: () => {},
    handleExitSubGraph: () => {},
    handleExternalDragDrop: () => {},
    handleSubgraphSelectedItems: () => {},
    handleRemoveSelected: () => {},
    handleSetRelationshipStrength: () => {},
  },
  graphHandler: {
    isDragging: false,
    handleLayoutGraph: () => {},
    handleSetLayout: () => {},
  },
  graphQuery: {
    handleSearch: () => Promise.resolve(),
    handleLoadAllUsers: () => Promise.resolve(),
    handleLoadAllTags: () => {},
    handleMyNetwork: () => {},
    handleCommunitySearch: () => Promise.resolve(),
  },
  quickActions: [],
  availableQuickActions: {},
  setCurrentGraphSnapshotId: () => {},
  loadGraphState: {
    clusteringEnabled: false,
    showRelationshipStrength: {},
    analyzerFunction: null,
    nodes: {},
    edges: {},
    openCombos: {},
  },
  setSavedLoadGraphState: () => {},
  options: undefined,
})

export default function useGraphContext() {
  return useContext(GraphContext)
}
