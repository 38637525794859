import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { IconLogout } from '@tabler/icons-react'
import { firstInitialOrFallback } from 'Features/CommunityUsers/communityUserNameUtil'

import { Avatar, Tooltip } from 'Components/UI'

import { EXTERNAL_LINKS } from 'Constants/ids'

import { useAppContext, useOnClickOutside } from 'Hooks'

import { PROFILE_ROOT, SIGN_OUT } from 'Router/routes'

import _ from 'Services/I18n'

import colors from 'Theme/Main/colors'

import {
  Dropdown,
  DropdownContainer,
  DropdownDivider,
  DropdownIconLink,
  DropdownLink,
  DropdownLinks,
  DropdownProfile,
  DropdownTrigger,
} from './styles'

function SidebarUser() {
  const { me } = useAppContext()
  const navigate = useNavigate()

  const firstNameInitial = firstInitialOrFallback({
    ...me,
    ...me?.profile,
  } as MainSchema.CommunityUser)
  const [opened, setOpened] = useState(false)

  const ref = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(ref, () => setOpened(false))

  return (
    <Dropdown ref={ref}>
      <Tooltip content={_('tips.profile')}>
        <DropdownTrigger
          aria-controls={opened ? 'profile-menu' : undefined}
          aria-expanded={opened}
          aria-haspopup="true"
          onClick={() => setOpened(value => !value)}
        >
          <Avatar
            isHeader
            outline
            src={me?.profile?.photoUrl}
            text={firstNameInitial}
          />
        </DropdownTrigger>
      </Tooltip>

      {opened && (
        <DropdownContainer id="profile-menu">
          {me?.communityUserId && (
            <DropdownProfile
              onClick={() => {
                setOpened(false)
                navigate(PROFILE_ROOT)
              }}
            >
              <Avatar
                small
                src={me?.profile?.photoUrl}
                text={firstNameInitial}
              />
              My Profile
            </DropdownProfile>
          )}
          <DropdownDivider />
          <DropdownLinks>
            <DropdownLink
              href={EXTERNAL_LINKS.privacyPolicy}
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Privacy Policy
            </DropdownLink>
            <DropdownLink
              href={EXTERNAL_LINKS.termsOfUse}
              rel="noopener noreferrer nofollow"
              target="_blank"
            >
              Terms of Use
            </DropdownLink>
            <DropdownIconLink href={SIGN_OUT}>
              <IconLogout color={colors.link.primary.color} /> Log Out
            </DropdownIconLink>
          </DropdownLinks>
        </DropdownContainer>
      )}
    </Dropdown>
  )
}

export default SidebarUser
