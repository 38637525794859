/** RBA Actions: read,create,edit,delete */
export enum Action {
  Create = 'create',
  Delete = 'delete',
  Edit = 'edit',
  Read = 'read',
}

/** AskOfferStatementKind: Ask,Offer */
export enum AskOfferStatementKind {
  Ask = 'Ask',
  Offer = 'Offer',
}

/** CommunityInvite: pending,accepted,declined,active,onboarding */
export enum CommunityInviteState {
  Accepted = 'accepted',
  Active = 'active',
  Declined = 'declined',
  Onboarding = 'onboarding',
  Pending = 'pending',
}

/** The kind of graph view */
export enum CommunityUserGraphViewKind {
  SavedGraph = 'SavedGraph',
  SystemGraph = 'SystemGraph',
}

/** CommunityUser status: pending,full,archived */
export enum CommunityUserStatus {
  Archived = 'archived',
  Full = 'full',
  Pending = 'pending',
}

/** ConnectAccountType states: signIn,signUp,connect,onboarding */
export enum ConnectAccountType {
  Connect = 'connect',
  Onboarding = 'onboarding',
  SignIn = 'signIn',
  SignUp = 'signUp',
}

/** EmailCredential states: pending,active,deactivated */
export enum EmailCredentialState {
  Active = 'active',
  Deactivated = 'deactivated',
  Pending = 'pending',
}

/** They type of grouping strategy to use */
export enum GroupRecommendationStrategy {
  AlignedAskOffer = 'AlignedAskOffer',
  Diverse = 'Diverse',
}

/** HubSpotState: pending,active,inactive,syncing */
export enum HubSpotState {
  Active = 'active',
  Inactive = 'inactive',
  Pending = 'pending',
  Syncing = 'syncing',
}

/** The different kinds of nodes in the knowledge graph */
export enum KnowledgeGraphNodeKind {
  Event = 'event',
  Location = 'location',
  Organization = 'organization',
  Person = 'person',
  Skill = 'skill',
  Technology = 'technology',
  Topic = 'topic',
}

/** MentionKind: addUser,meetUser,skill,needSkill,event,project,role,custom,group */
export enum MentionKind {
  AddUser = 'addUser',
  Custom = 'custom',
  Event = 'event',
  Group = 'group',
  MeetUser = 'meetUser',
  NeedSkill = 'needSkill',
  Project = 'project',
  Role = 'role',
  Skill = 'skill',
}

/** NoteSource: networkOS,nylas */
export enum NoteSource {
  NetworkOs = 'networkOS',
  Nylas = 'nylas',
}

/** NoteTargetEntityKind: user,tag */
export enum NoteTargetEntityKind {
  Tag = 'tag',
  User = 'user',
}

/** NotificationKind: proposal,nylasGrantExpired */
export enum NotificationKind {
  NylasGrantExpired = 'nylasGrantExpired',
  Proposal = 'proposal',
}

/** OAuthProvider: google,microsoft,hubspot */
export enum OAuthProvider {
  Google = 'google',
  Hubspot = 'hubspot',
  Microsoft = 'microsoft',
}

/** OAuthResponseKind: success,exists,notFound,created,deleted,requiresConsent */
export enum OAuthResponseKind {
  Created = 'created',
  Deleted = 'deleted',
  Exists = 'exists',
  NotFound = 'notFound',
  RequiresConsent = 'requiresConsent',
  Success = 'success',
}

/** PrivacySettingKind: name,profilePhoto,email,phoneNumber,socialLinks,companyInfo,skillsTags,publicNotes */
export enum PrivacySettingKind {
  CompanyInfo = 'companyInfo',
  Email = 'email',
  Name = 'name',
  PhoneNumber = 'phoneNumber',
  ProfilePhoto = 'profilePhoto',
  PublicNotes = 'publicNotes',
  SkillsTags = 'skillsTags',
  SocialLinks = 'socialLinks',
}

/** ProposalKind: firstName,lastName,fullName,email,photoUrl,linkedInUrl,twitterUrl,facebookUrl,about,interestsHobbies,organization,jobTitle,connection,phone,skill,needSkill,event,project,role,group,custom,industry,needIndustry */
export enum ProposalKind {
  About = 'about',
  Connection = 'connection',
  Custom = 'custom',
  Email = 'email',
  Event = 'event',
  FacebookUrl = 'facebookUrl',
  FirstName = 'firstName',
  FullName = 'fullName',
  Group = 'group',
  Industry = 'industry',
  InterestsHobbies = 'interestsHobbies',
  JobTitle = 'jobTitle',
  LastName = 'lastName',
  LinkedInUrl = 'linkedInUrl',
  NeedIndustry = 'needIndustry',
  NeedSkill = 'needSkill',
  Organization = 'organization',
  Phone = 'phone',
  PhotoUrl = 'photoUrl',
  Project = 'project',
  Role = 'role',
  Skill = 'skill',
  TwitterUrl = 'twitterUrl',
}

/** ProposalStatus: accepted,pending,rejected */
export enum ProposalStatus {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected',
}

/** RBA Scopes: own,other,public,private,target */
export enum Scope {
  Other = 'other',
  Own = 'own',
  Private = 'private',
  Public = 'public',
  Target = 'target',
}

/** Sort input order: asc,desc */
export enum SortInputOrder {
  Asc = 'asc',
  Desc = 'desc',
}

/** Sources: networkOS,hubSpot,nylas,nylasEmail,nylasContacts,nylasCalendar,peopleDataLabs */
export enum Source {
  HubSpot = 'hubSpot',
  NetworkOs = 'networkOS',
  Nylas = 'nylas',
  NylasCalendar = 'nylasCalendar',
  NylasContacts = 'nylasContacts',
  NylasEmail = 'nylasEmail',
  PeopleDataLabs = 'peopleDataLabs',
}

/** RBA Subjects: note,invite,account,adminPolicy,adminOnboarding,hubspot,metrics,uploadData,managementTag,managementIndustriesTag,managementSkill,managementRba,apiKey,askOffer */
export enum Subject {
  Account = 'account',
  AdminOnboarding = 'adminOnboarding',
  AdminPolicy = 'adminPolicy',
  ApiKey = 'apiKey',
  AskOffer = 'askOffer',
  Hubspot = 'hubspot',
  Invite = 'invite',
  ManagementIndustriesTag = 'managementIndustriesTag',
  ManagementRba = 'managementRba',
  ManagementSkill = 'managementSkill',
  ManagementTag = 'managementTag',
  Metrics = 'metrics',
  Note = 'note',
  UploadData = 'uploadData',
}

/** Survey steps: profile,connections,knowledge,needs,work,thanks */
export enum SurveyStep {
  Connections = 'connections',
  Knowledge = 'knowledge',
  Needs = 'needs',
  Profile = 'profile',
  Thanks = 'thanks',
  Work = 'work',
}

/** TagKind: event,project,role,custom,group,industry */
export enum TagKind {
  Custom = 'custom',
  Event = 'event',
  Group = 'group',
  Industry = 'industry',
  Project = 'project',
  Role = 'role',
}

/** Timeframe: hour,day,week,month,quarter,year */
export enum Timeframe {
  Day = 'day',
  Hour = 'hour',
  Month = 'month',
  Quarter = 'quarter',
  Week = 'week',
  Year = 'year',
}

export enum UserConnectionResultKind {
  Existing = 'EXISTING',
  New = 'NEW',
}

/** The sentiment of the user feedback */
export enum UserFeedbackSentiment {
  Negative = 'Negative',
  Neutral = 'Neutral',
  Positive = 'Positive',
}

/** UserRelationship: dontKnow,weak,moderate,strong */
export enum UserRelationship {
  DontKnow = 'dontKnow',
  Moderate = 'moderate',
  Strong = 'strong',
  Weak = 'weak',
}

/** User roles: user,superAdmin */
export enum UserRole {
  SuperAdmin = 'superAdmin',
  User = 'user',
}

/** User states: active,deactivated */
export enum UserState {
  Active = 'active',
  Deactivated = 'deactivated',
}
